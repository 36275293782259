import "../css/header.css";
import logo from "../../images/logo-black.png";

export default function Header() {
  return (
    <div className="navigation-bar-container">
      <div style={{ marginLeft: "2em" }}>
        <button className="btn">
          <img src={logo} style={{ height: "3em" }} alt="Piraeus logo"></img>
        </button>
      </div>

      <div style={{ marginRight: "2em" }}>
        <a className="btn btn-lg" href="https://instagram.com/piraeusmusic_">
          <i className="bi bi-instagram"></i>
        </a>

        <button className="btn btn-lg">
          <i className="bi bi-twitter-x"></i>
        </button>
      </div>
    </div>
  );
}
