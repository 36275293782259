import { CSSProperties } from "react";

interface Props {
  heading: string;
  style: CSSProperties;
  children: string;
}

export default function Card({ heading, style, children }: Props) {
  return (
    <div className="card" style={style}>
      <div className="card-body">
        <h5 className="card-title">{heading}</h5>
        <p className="card-text">{children}</p>
      </div>
    </div>
  );
}
