import "../css/footer.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <button className="btn" style={{ marginLeft: "2em", color: "white" }}>
        Piraeus © 2024
      </button>

      <div style={{ marginRight: "2em" }}>
        <a
          className="btn btn-lg"
          href="https://instagram.com/piraeusmusic_"
          style={{ color: "white" }}
        >
          <i className="bi bi-instagram"></i>
        </a>

        <button className="btn btn-lg" style={{ color: "white" }}>
          <i className="bi bi-twitter-x"></i>
        </button>
      </div>
    </div>
  );
}
