import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import Header from "./components/navigation/Header";
import ContentBlock from "./components/ContentBlock";
import Alert from "./components/Alert";
import Footer from "./components/navigation/Footer";
import Card from "./components/Card";

import "./App.css";

export default function App() {
  return (
    <>
      <Header />
      <Alert />
      <ContentBlock />

      <div
        style={{
          margin: "2em",
          marginBottom: 0,
          height: "5em",
          borderTop: "1px solid grey",
          alignContent: "center",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Why the Key-Pick?</h3>
      </div>
      <div
        className="card-container"
        style={{
          margin: "2em",
          marginTop: 0,
          marginBottom: 0,
          borderBottom: "1px solid grey",
        }}
      >
        <Card
          heading="Convenience"
          style={{ width: "18rem", marginTop: "2em", marginBottom: "2em" }}
        >
          No need to stop and look up notes online or in a book. The Key-Pick
          keeps the necessary information right at your fingertips.
        </Card>

        <Card
          heading="Improved Learning"
          style={{ width: "18rem", marginTop: "2em", marginBottom: "2em" }}
        >
          Speeds up the learning process by providing an easy reference, making
          practice sessions more effective and less frustrating.
        </Card>

        <Card
          heading="Enhanced Practice"
          style={{ width: "18rem", marginTop: "2em", marginBottom: "2em" }}
        >
          Helps guitarists focus more on playing and less on figuring out which
          notes to play, leading to more productive practice sessions.
        </Card>

        <Card
          heading="Portability"
          style={{ width: "18rem", marginTop: "2em", marginBottom: "2em" }}
        >
          As small and portable as any standard guitar pick, the Key-Pick can be
          taken anywhere, making it perfect for on-the-go practice.
        </Card>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "2em",
        }}
      >
        <h3 style={{ marginBottom: "1em", fontVariant: "all-small-caps" }}>
          Ready to enhance your learning?
        </h3>
        <h1 style={{ marginBottom: "1em" }}>Pre-order the Key-Pick!</h1>
        <button
          className="btn btn-primary"
          style={{
            width: "auto",
            backgroundColor: "green",
            borderColor: "white",
          }}
        >
          Pre-order now!
        </button>
      </div>
      <Footer />
    </>
  );
}
