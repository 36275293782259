import "./css/content-block.css";
import demo from "../images/demo.png";

export default function ContentBlock() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h4 style={{ textAlign: "center", fontVariant: "all-small-caps" }}>
        Introducing the
      </h4>
      <h1 style={{ textAlign: "center" }}>Key-Pick</h1>
      <div className="content-container">
        <div>
          <img src={demo} alt="Key pick" />
        </div>
      </div>
      <h2 style={{ textAlign: "center" }}>
        The easiest way to learn music theory!
      </h2>
      <button
        className="btn btn-primary"
        style={{
          width: "auto",
          backgroundColor: "green",
          borderColor: "white",
          margin: "1em",
        }}
      >
        Pre-order now!
      </button>
    </div>
  );
}
