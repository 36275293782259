export default function Alert() {
  return (
    <div
      className="alert alert-info"
      role="alert"
      style={{ margin: "2em", textAlign: "center" }}
    >
      Our online store is currently in progress - stay tuned for updates on our
      Instagram!
    </div>
  );
}
